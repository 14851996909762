import React from 'react';
import Slider from 'react-slick';
import { useStaticQuery, graphql } from 'gatsby';
import { PictureTag } from '../Common';
import styles from './Carousel.module.css';

const staticQuery = graphql`
  query CarouselImgsQuery {
    sourceHomePage {
      carouselImgs {
        digest
        sources {
          localFile {
            childImageSharp {
              fluid(quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          media
        }
      }
    }
  }
`;

export default function Carousel({ className, ...props }) {
  const {
    sourceHomePage: { carouselImgs },
  } = useStaticQuery(staticQuery);
  return (
    <props.name className={className}>
      <Slider
        dots
        arrows={false}
        infinite
        autoplay
        slidesToShow={1}
        slidesToScroll={1}
        autoplaySpeed={4000}
        dotsClass={`${styles.dots} slick-dots`}
      >
        {carouselImgs.map(({ digest, sources }) => (
          <PictureTag
            key={digest}
            id={digest}
            sources={sources}
            className={styles.image}
          />
        ))}
      </Slider>
    </props.name>
  );
}
