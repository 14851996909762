import React from 'react';
import Countdown, { zeroPad } from 'react-countdown-now';
import styles from './CountdownTimer.module.css';

const renderer = ({ days, hours, minutes, seconds, completed }) =>
  !completed && (
    <>
      <span>
        <em>{zeroPad(days)}</em>
        Days
      </span>
      <span>
        <em>{zeroPad(hours)}</em>
        Hr
      </span>
      <span>
        <em>{zeroPad(minutes)}</em>
        Min
      </span>
      <span>
        <em>{zeroPad(seconds)}</em>
        Sec
      </span>
      <p>left. Don&apos;t miss out!</p>
    </>
  );

const getURLSearchParam = urlParamKey => {
  if (typeof window !== 'undefined' && window.URL && window.URLSearchParams) {
    const { search } = new URL(window.location);
    if (search) {
      const searchParams = new URLSearchParams(search);
      return searchParams.get(urlParamKey);
    }
  }
  return null;
};

const CountdownTimer = () => {
  const endDate = getURLSearchParam('countdownTimerEndDate');
  const countDownBackground = getURLSearchParam('countDownBG');
  return (
    <div
      className={styles.countDownTimer}
      style={{ background: `#${countDownBackground}` }}
    >
      <div className={styles.countDownTimerInner}>
        {endDate && <Countdown date={endDate} renderer={renderer} />}
      </div>
    </div>
  );
};

export default CountdownTimer;

// SS url query string countDownBG=#fff200&countdownTimerEndDate=Dec 02, 2019 23:59:59
