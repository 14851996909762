import { useEffect } from 'react';

const {
  DATADOG_CLIENT_TOKEN = 'pub0d55a81d433db22eab4585fa5a4f8774',
} = process.env;

const DatadogLogs = () => {
  useEffect(() => {
    if (window && window.DD_LOGS) {
      window.DD_LOGS.init({
        clientToken: DATADOG_CLIENT_TOKEN,
        forwardErrorsToLogs: true,
      });
      window.DD_LOGS.addLoggerGlobalContext('service', 'homepage');
    }
  }, []);
  return null;
};

export default DatadogLogs;
