import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import Slider from 'react-slick';
import styles from './Advices.module.css';

const AdviceItem = ({ h2, span, href, childImageSharp: { fluid, fixed } }) => (
  <a
    href={href}
    className={`l-col l-one-fourth advice-and-articles-item ${styles.link}`}
  >
    <div className="advice-item-image-container">
      <Img fluid={fluid} fixed={fixed} />
    </div>
    <div className="advice-item-text-container">
      <h2>{h2}</h2>
      <span>{span}</span>
    </div>
  </a>
);

const staticQuery = graphql`
  query AdviceImgsQuery {
    sourceHomePage {
      adviceImgs {
        h2
        span
        href
        localFile {
          id
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      fixedAdviceImgs: adviceImgs {
        h2
        span
        href
        localFile {
          id
          childImageSharp {
            fixed(width: 280, height: 280) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
    }
  }
`;

export default function Advices({ className, ...domNode }) {
  const {
    sourceHomePage: { adviceImgs, fixedAdviceImgs },
  } = useStaticQuery(staticQuery);
  return (
    <>
      <domNode.name className={`${className} ${styles.advices}`}>
        {adviceImgs.map(
          ({ h2, span, href, localFile: { id, childImageSharp } }) => (
            <li key={id}>
              <AdviceItem
                h2={h2}
                span={span}
                href={href}
                childImageSharp={childImageSharp}
              />
            </li>
          ),
        )}
      </domNode.name>
      <Slider
        dots
        arrows={false}
        infinite
        autoplay
        className={styles.slider}
        slidesToShow={1}
        slidesToScroll={1}
        autoplaySpeed={4000}
        dotsClass={`${styles.dots} slick-dots`}
      >
        {fixedAdviceImgs.map(
          ({ h2, span, href, localFile: { id, childImageSharp } }) => (
            <AdviceItem
              key={id}
              h2={h2}
              span={span}
              href={href}
              childImageSharp={childImageSharp}
            />
          ),
        )}
      </Slider>
    </>
  );
}
