/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-danger */
import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import HTMLReactParser, { domToReact } from 'html-react-parser';
import Helmet from 'react-helmet';
import _keyBy from 'lodash/keyBy';
import Carousel from '../components/Carousel';
import { RRPlacement } from '../components/Home';
import Advices from '../components/Advices';
import { PictureTag } from '../components/Common';
import Page from '../components/Page/Page';
import DatadogLogs from '../components/DatadogLogs';
import CountdownTimer from '../components/CountdownTimer/CountdownTimer';

function searchFieldValidate() {
  // copy/paste from hollandandbarrett.com/ui/js/legacy/legacy.js
  var value = document.getElementById('sitesearch').value;
  if (value == '' || value.replace(/[^A-Za-z0-9]+/g, '') == '') {
    return false;
  }
}

const IndexPage = ({
  data: {
    allHeadTag,
    sourceHomePage: {
      body,
      images,
      img35,
      img60,
      pictures,
      bodyAttributes,
      htmlAttributes,
    },
  },
}) => {
  const imgBySrc = _keyBy([...images, ...img35, ...img60], 'imgUrl');
  const pictureByDigest = _keyBy(pictures, 'digest');

  return (
    <>
      <Helmet
        bodyAttributes={JSON.parse(bodyAttributes)}
        htmlAttributes={JSON.parse(htmlAttributes)}
        encodeSpecialCharacters={false}
      >
        <script
          type="text/javascript"
          src="https://www.datadoghq-browser-agent.com/datadog-logs-eu.js"
        />
        {allHeadTag.nodes.map(node => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <node.name key={node.id} {...JSON.parse(node.attribs)}>
            {node.text}
          </node.name>
        ))}
      </Helmet>
      <Page searchHidden>
        <DatadogLogs />
        {HTMLReactParser(body, {
          // eslint-disable-next-line consistent-return
          replace: ({ attribs, children, ...domNode }) => {
            if (attribs) {
              const { src = attribs['data-src'], class: cl = '' } = attribs;
              const className = cl.replace(/\s*lazyload/g, '');
              if (className.includes('homepage-carousel-holder')) {
                return <Carousel name={domNode.name} className={className} />;
              }
              if (className.includes('site-search-form')) {
                return (
                  <domNode.name
                    action={attribs.action}
                    method={attribs.method}
                    className={className}
                    onSubmit={searchFieldValidate}
                  >
                    {domToReact(children)}
                  </domNode.name>
                );
              }
              if (attribs['data-rr-placement'] === 'home_page.banner1') {
                return (
                  <div>
                    <CountdownTimer />
                    <RRPlacement name={domNode.name} attribs={attribs} />
                  </div>
                );
              }
              if (attribs['data-rr-placement']) {
                return <RRPlacement name={domNode.name} attribs={attribs} />;
              }
              if (attribs.id === 'advice-and-articles-list') {
                return <Advices name={domNode.name} className={className} />;
              }
              if (attribs.src && attribs.src.includes('/jquery.min.js')) {
                return (
                  <div
                    dangerouslySetInnerHTML={{
                      // NOTE: script tags are ignored running the app locally
                      __html: `
                      <script>
                        window.RUN_AFTER_JQUERY = function () {}
                      </script>
                      <script src=${attribs.src} onload="RUN_AFTER_JQUERY()"></script>
                    `,
                    }}
                  />
                );
              }
              if (src in imgBySrc) {
                const { childImageSharp } = imgBySrc[src].localFile;
                return (
                  childImageSharp && (
                    <Img {...childImageSharp} className={className} />
                  )
                );
              }

              if (attribs['data-digest'] in pictureByDigest) {
                const { sources, loading } = pictureByDigest[
                  attribs['data-digest']
                ];
                const isfirstBanner =
                  attribs['data-digest'] === pictures[0].digest;
                return (
                  <div>
                    {isfirstBanner ? <CountdownTimer /> : null}
                    <PictureTag
                      loading={loading}
                      id={attribs['data-digest']}
                      sources={sources}
                    />
                  </div>
                );
              }
            }
          },
        })}
      </Page>
    </>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query {
    sourceHomePage {
      body
      categories {
        h2
      }
      pictures {
        digest
        loading
        sources {
          media
          base64
          localFile {
            childImageSharp {
              fluid(quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      images {
        imgUrl
        localFile {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      img35 {
        imgUrl
        localFile {
          childImageSharp {
            fixed(width: 35, height: 35, quality: 90) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
      img60 {
        imgUrl
        localFile {
          childImageSharp {
            fixed(width: 60, height: 60, quality: 90) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
      bodyAttributes
      htmlAttributes
    }
    allHeadTag {
      nodes {
        id
        name
        attribs
        text
      }
    }
  }
`;
